<template>

    <div id="about">
<!--        <img :src="require('@/assets/images/about/bg.png')" alt="" class="bg">-->
        <div class="content">
            <div class="top">
                <img :src="require('@/assets/images/about/pic_mold.png')" alt="">
            </div>

            <!-- 邦宝模具简介 -->
            <div class="intr">
                <h2>邦宝塑胶模具中心简介</h2>
                <!--  v-if="data.mjjj.data" -->
                <div class="lf">
                    <!--<p>{{ isEn ? data.mjjj.data.enContentSummary : data.mjjj.data.cnContentSummary }}</p>-->

                    <p>
                            邦宝益智以智能工厂为战略建设标的，引进先进的智能化模具设备，包括立式综合加工机、精雕CNC雕刻、
                        慢走丝线切割机、数控电火花机床、立式合模机等，模具车间采用CAD\CAE\CAM软件平台，
                        通过无线RFID远程将编程导入高速数控机台，进行各道工序数据互联互通，最后形成数据管理看板；
                        模具检验采用三坐标检测设备。
                        <br/><span style="margin-left: 40px;">公司围绕六大信息系统（ERP\MES\WMS\PLM\HR\BPM）制定信息化高效管理流程，分别通过ISO9001质量管理体系、
                        ISO14001环境管理体系、ISO45001职业健康安全管理体系、ICTI玩具业责任规范等多维体系认证。
                        产品质量通过国内3C认证、欧洲EN71认证、美国ASTM F963认证。</span>
                    </p>
                </div>
                <div class="rf">
                    <!--<img v-for="img in data.mjjj.data.files" :key="img"
                         :src="staticPath + img.fileUrl"  alt=""/>-->

                    <!--                    <img :src="require('@assets/images/products/bbmjjj.png')"  alt=""/>-->

                    <img :src="require('@assets/images/products/1.jpg')"  alt=""/>
                    <img :src="require('@assets/images/products/2.jpg')"  alt=""/>
                    <img :src="require('@assets/images/products/3.jpg')"  alt=""/>
                    <img :src="require('@assets/images/products/4.jpg')"  alt=""/>
                    <img :src="require('@assets/images/products/5.jpg')"  alt=""/>
                    <img :src="require('@assets/images/products/6.jpg')"  alt=""/>


                </div>
            </div>

            <!-- 创新工作室 -->
            <div :style="{'visibility': aniObj.cultureVisible ? 'visible' : 'hidden'}" :class="['culture', 'animate__animated', aniObj.culture ? 'animate__fadeInUp' : '']" ref="culture">
                <h2 class="badge">{{ isEn ? data.cxgzs.enName : data.cxgzs.cnName }}</h2>
                <div class="box">
                    <!--  v-if="data.cxgzs.data" -->
                    <div class="lf">
                        <!--<p>{{ isEn ? data.cxgzs.data.enContentSummary : data.cxgzs.data.cnContentSummary }}</p>-->

                        <p>
                            塑胶模具中心总监陈进喜于2020年11月24日，被表彰为2020年全国劳动模范。2021年12月24日，
                            经广东省总工会批准命名“陈进喜创新工作室”，发挥示范引领、集智创新、协同攻关、传承技能、
                            培育精神等功能，激励广大职工勤于创造、勇于奋斗。
                        </p>
                    </div>
                    <div class="rf">
                        <!--<img v-for="img in data.cxgzs.data.files" :key="img"
                             :src="staticPath + img.fileUrl"  alt=""/>-->

                        <img :src="require('@assets/images/products/cxgzs.png')"  alt=""/>
                    </div>
                </div>
            </div>

            <!-- 产品展示 -->
            <div :style="{'visibility': aniObj.honorVisible ? 'visible' : 'hidden'}" :class="['honor', 'animate__animated', aniObj.honor ? 'animate__fadeInUp' : '']" ref="honor">
                <h2 class="badge">{{ isEn ? data.cpzs.enName : data.cpzs.cnName }}</h2>
                <!--  v-if="data.cpzs.data" -->
                <div class="honor-list">
                    <!--<swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in data.cpzs.data.files" :key="index">
                            <div class="pic">
                                <img :src="staticPath + item.fileUrl" @click="showImgPreview(item.fileUrl)" alt />
                            </div>
                            <p>{{ isEn ? item.fileEnName : item.fileCnName }}</p>
                        </swiper-slide>
                        &lt;!&ndash;<div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>&ndash;&gt;
                        &lt;!&ndash;<div class="swiper-button-prev" slot="button-prev"></div>&ndash;&gt;
                        &lt;!&ndash;<div class="swiper-button-next" slot="button-next"></div>&ndash;&gt;
                    </swiper>-->

                    <img :src="require('@assets/images/products/cpzs.png')"  alt=""/>
                </div>
            </div>

            <!-- 合作伙伴 -->
            <div :style="{'visibility': aniObj.hzhbVisible ? 'visible' : 'hidden'}" :class="['hzhb', 'animate__animated', aniObj.hzhb ? 'animate__fadeInUp' : '']" ref="hzhb">
                <h2 class="badge">合作伙伴</h2>
                <div class="box">
                    <img :src="require('@assets/images/products/hzhb.png')"  alt=""/>
                    <div class="_row">
                        <img :src="require('@assets/images/products/hzhb_pls.png')"  alt=""/>
                        <img :src="require('@assets/images/products/hzhb_ng.png')"  alt=""/>
                        <img :src="require('@assets/images/products/hzhb_h.png')"  alt=""/>
                    </div>
                </div>
            </div>

            <!-- 联系方式 -->
            <div :style="{'visibility': aniObj.otheritemsVisible ? 'visible' : 'hidden'}" :class="['other-items', 'animate__animated', aniObj.otheritems ? 'animate__fadeInUp' : '']" ref="otheritems">
                <h2 class="badge">{{ isEn ? data.lxfs.enName : data.lxfs.cnName }}</h2>
                <div class="box">
                    <!--  v-if="data.lxfs.data" -->
                    <div class="lf">
                        <!--<img v-for="img in data.lxfs.data.files" :key="img"
                             :src="staticPath + img.fileUrl"  alt=""/>-->

                        <img :src="require('@assets/images/products/lxfs.png')"  alt=""/>
                    </div>
                    <div class="rf">
                        <!--<p>联系人：{{ data.lxfs.data.cnContentSummary }}</p>
                        <p>联系电话：{{ data.lxfs.data.enContentSummary }}</p>
                        <p>邮箱：{{ data.lxfs.data.redirectUrl }}</p>-->
                        <p>业务负责人：沈生</p>
                        <p>联系电话：18807548383</p>
                        <p><span style="opacity: 0">联系电话：</span>13829622128</p>
                    </div>
                </div>
            </div>

        </div>

        <!-- 组件 -->
        <img-preview v-model="isShowImgPreview" :imgPath="imgPreviewPath"></img-preview>
    </div>

</template>

<script>

    import "swiper/dist/css/swiper.css";
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import {urls, staticPath} from '@/lib/common';
    import imgPreview from '@components/imgPreview';

    export default {
        name: "About",
        components: {
            imgPreview,
            swiper,
            swiperSlide
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper;
            },
            calcTimelineLength() {
                if(document.body.clientWidth > 750)
                    return this.data.fzlc.data.length * 15 + 'vw';
                else
                    return this.data.fzlc.data.length * 25 + 6 + '%';
            }
        },
        watch: {
            "$i18n.locale": function(val) {
                val === "en" ? this.isEn = true : this.isEn = false;
            }
        },
        data() {
            return {
                isShowImgPreview: false,
                imgPreviewPath: '',

                swiperOptions: {
                    slidesPerView: document.body.clientWidth > 750 ? 4 : 2,
                    slidesPerColumnFill : 'row',
                    slidesPerColumn: 2,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                },
                staticPath,
                tempId: 188,
                isEn: false,
                processTimelineIndex: 0,
                stepWidth: 0,
                data: "",
                cultureScrollTop: '',
                honorScrollTop: '',
                processScrollTop: '',
                developScrollTop: '',
                otheritemsScrollTop: '',
                aniObj: {
                    cultureVisible: false,
                    culture: false,
                    honorVisible: false,
                    honor: false,
                    hzhb: false,
                    hzhbVisible: false,
                    otheritemsVisible: false,
                    otheritems: false,
                },

            }
        },
        methods: {
            chooseYear(index) { // 发展历程-选择年份
                const timeline = this.$refs.timeline;
                const currPosition = timeline.children[0].children[index].offsetLeft;
                const liWidth = timeline.children[0].children[0].offsetWidth;
                this.processTimelineIndex = index;
                if (index >= 3) {
                    let timer = setInterval(() => {
                        if (this.stepWidth >= currPosition - liWidth / 2) {
                            clearInterval(timer);
                            timer = null;
                        } else {
                            timeline.scrollTo(this.stepWidth+=(liWidth/10), 0)
                        }
                    }, 10)
                } else {
                    let timer = setInterval(() => {
                        if (this.stepWidth + liWidth / 2 < currPosition - liWidth / 2) {
                            clearInterval(timer);
                            timer = null;
                        } else {
                            timeline.scrollTo(this.stepWidth-=(liWidth/10), 0)
                        }
                    }, 10)
                }
            },
            getTemplates() {
                console.log(this.$route.params.navigationId.split('n=')[1]);
                console.log(atob(decodeURIComponent(this.$route.params.navigationId.split('n=')[1])))
                this.tempId = atob(decodeURIComponent(this.$route.params.navigationId.split('n=')[1]))

                return this.$http.get(urls.productMould + this.tempId).then(res => {
                    if (res.data.status === 200) {
                        this.data = res.data.data;
                        if (this.data.fzlc && this.data.fzlc.data) {
                            var sortNumber = (a,b) => {
                                return (this.isEn ? b.enTitle - a.enTitle : b.cnTitle - a.cnTitle)
                            }
                            this.data.fzlc.data.sort(sortNumber)
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },

            // 监听页面滚动
            getScrollTop() {
                var scroll_top = 0;
                if (document.documentElement && document.documentElement.scrollTop) {
                    scroll_top = document.documentElement.scrollTop;
                } else if (document.body) {
                    scroll_top = document.body.scrollTop;
                }
                return scroll_top;
            },
            listenPageScrollFun() {
                document.addEventListener('scroll', this.zoomControl, true)
            },
            zoomControl() {
                var diffValue = -(document.body.clientHeight * 0.5);

                this.winScrollHeight = this.getScrollTop();

                // if (this.cultureScrollTop < this.winScrollHeight + 600) {
                if (!this.aniObj.culture && this.winScrollHeight >= this.$refs.culture.offsetTop + diffValue) {
                    this.aniObj.culture = true;
                    this.aniObj.cultureVisible = true;
                }
                //if (this.honorScrollTop < this.winScrollHeight + 650) {
                if (!this.aniObj.honor && this.winScrollHeight >= this.$refs.honor.offsetTop + diffValue) {
                    this.aniObj.honor = true;
                    this.aniObj.honorVisible = true;
                }
                if (!this.aniObj.hzhb && this.winScrollHeight >= this.$refs.hzhb.offsetTop + diffValue) {
                    this.aniObj.hzhb = true;
                    this.aniObj.hzhbVisible = true;
                }
                //if (this.otheritemsScrollTop < this.winScrollHeight + 600) {
                if (!this.aniObj.otheritems && this.winScrollHeight >= this.$refs.otheritems.offsetTop + diffValue) {
                    this.aniObj.otheritems = true;
                    this.aniObj.otheritemsVisible = true;
                }
            },


            showImgPreview: function(imageSrc) {
                console.log(imageSrc);
                this.imgPreviewPath = this.man.fast.getResourcePath(imageSrc);
                this.isShowImgPreview = true;
            },
        },
        beforeDestroy() {
            document.removeEventListener('scroll', this.zoomControl, true)
        },
        mounted() {
            this.getTemplates().then(() => {
                this.cultureScrollTop = this.$refs.culture.offsetTop;
                // this.honorScrollTop = this.$refs.honor.offsetTop;
                // this.otheritemsScrollTop = this.$refs.otheritems.offsetTop;
                this.listenPageScrollFun()
            })
        }
    };

</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    @pointWidth: 13vw;
    #about {
        .content {
            width: 100%;
            z-index: 99;
            .top {
                background: url('../../assets/images/about/block/1.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                max-height: 669px;
                margin: 0 auto;
                text-align: right;
                img {width: 70%;}
            }
            .intr {
                background: url('../../assets/images/about/block/2.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                margin: 0 auto;
                padding: 5vw 12vw;
                color: #353968;

                >h2 {
                    position: relative;
                    font-size: 1.9vw;
                    margin: -2vw 0 2vw 0;
                }
                &::after {
                    content: '';
                    display: block;
                    clear: both;
                }
                .lf {
                    float: left;
                    width: 50%;
                    line-height: 2.8vw;
                    p {
                        color: #343750;
                        font-size: 1.1vw;
                        text-indent: 2em;
                        padding-right: 2em;
                        margin-bottom: 20px;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 12;
                    }
                }
                .rf {
                    //background-color: #00A66C;
                    float: right;
                    width: 50%;
                    padding: 0 0 3.5vw 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    img {
                        //width: 22vw;
                        //height: 22vw;

                        width: 12vw;
                        height: 12vw;
                        margin-bottom: 1vw;
                    }
                }
            }
            .badge {
                font-size: 1.9vw;
                color:#353968;
                text-align: center;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 1.8vw;
                    height: 1.8vw;
                    background: url('../../assets/images/about/icon.png') no-repeat center center;
                    background-size: 100% 100%;
                    position: relative;
                    top: -0.8vw;
                    right: 0.3vw;
                }
            }
            .culture {
                background: url('../../assets/images/about/block/3.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                margin: -5vw auto 0;
                padding: 5vw 15vw;
                color: #353968;

                >h2 {
                    position: relative;
                    //top: -5vw;
                    font-size: 1.9vw;
                }
                &::after {
                    content: '';
                    display: block;
                    clear: both;
                }
                .lf {
                    float: left;
                    width: 40%;
                    margin: 5vw 0 0 10%;
                    line-height: 3.125vw;
                    p {
                        color: #343750;
                        font-size: 1vw;
                        text-indent: 2em;
                        padding-right: 2em;
                        margin-bottom: 20px;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 5;
                    }
                }
                .rf {
                    float: right;
                    width: 50%;
                    margin-top: 4vw;

                    img {
                        width: 34vw;
                        height: 20vw;
                    }
                }
            }
            .honor {
                background: url('../../assets/images/about/block/4.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                margin: 0 auto;
                padding: 1vw 0 4vw 0;

                >h2 {
                    position: relative;
                    top: 3vw;
                    color: #fff;
                    font-size: 28px;
                }
                .honor-list {
                    width: 75vw;
                    margin: 6vw auto 2vw;
                    text-align: center;
                    color: #fff;

                    img {width: 45vw;}
                }
            }
            .hzhb {
                background: url('../../assets/images/about/block/5.png') no-repeat left top;
                background-size: 100% 100%;
                width: 100%;
                padding: 5vw 0 0 0;

                .box {
                    //background-color: #00A66C;
                    width: 60vw;
                    margin: 5vw auto 10vw auto;
                    display: flex;
                    flex-wrap: wrap;

                    ._row {
                        margin: 4vw 0 0 0;
                        img {
                            width: 10vw;
                            margin: 0 3vw 0 3vw;
                        }
                    }
                }
            }
            .other-items {
                //background: url('../../assets/images/about/block/6.png') no-repeat left top;
                background-color: white;
                background-size: 100% 100%;
                width: 100%;
                max-height: 798px;

                .box {
                    height: 30vw;
                    padding: 5vw 7vw;

                    .lf {
                        float: left;
                        width: 50%;
                        padding-left: 10vw;
                        text-align: right;

                        img {
                            width: 22vw;
                            max-width: 250px;
                            height: 22vw;
                            max-height: 250px;
                        }
                    }
                    .rf {
                        float: right;
                        width: 50%;
                        //line-height: 3.125vw;
                        padding: 4vw 0;

                        p {
                            color: #343750;
                            font-size: 1.2vw;
                            text-indent: 2em;
                            padding-right: 2em;
                            margin-bottom: 20px;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 8;
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #about {
            .content{
                .top {
                    img {width: 88%;}
                }
                .intr {
                    padding: 5vw 6vw 0 ;

                    >h2 {
                        .fontFn(14);
                        margin-left: 10px;
                    }
                    .lf {
                        width: 60%;
                        p {
                            .fontFn(12);
                            line-height: 4vw;
                            -webkit-line-clamp: 12;
                        }
                    }
                    .rf {
                        //background-color: #00A66C;
                        width: 40%;
                        padding: 0 0 10vw 0;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        img {
                            //width: 35vw;
                            //height: 35vw;

                            width: 17vw;
                            height: 17vw;
                            margin-bottom: 1vw;
                        }
                    }
                }
                .badge {
                    .fontFn(14);
                    &::after {
                        width: 3vw;
                        height: 3vw;
                    }
                }
                .culture {
                    //margin: -7vw auto 1vw auto;
                    padding: 5vw 0 2vw;

                    >h2 {
                        //top: 3vw;
                        .fontFn(14);
                    }
                    .lf {
                        width: 42%;
                        margin: 3vw 0 0 5%;
                        line-height: 4vw;
                        p {
                            .fontFn(11);
                            -webkit-line-clamp: 8;
                        }
                    }
                    .rf {
                        width: 53%;

                        img {
                            width: 45vw;
                            height: 28vw;
                        }
                    }
                }
                .honor {
                    padding: 3vw 0 5vw;
                    >h2 {
                        .fontFn(14);
                    }
                    .honor-list {
                        img {width: 60vw;}
                    }
                }
                .hzhb {
                    padding: 5vw 0;

                    //img {width: 80vw; margin: 5vw 0 10vw 0;}


                    .box {
                        //background-color: #00A66C;
                        width: 90vw;
                        margin: 5vw auto 10vw auto;
                        display: flex;
                        flex-wrap: wrap;

                        ._row {
                            margin: 4vw 0 0 0;
                            img {
                                width: 18vw;
                                margin: 0 3vw 0 3vw;
                            }
                        }
                    }

                }
                .other-items {
                    //background-color: #00A66C;
                    .box {
                        .lf {
                            float: left;
                            width: 40%;
                            padding-left: 10vw;
                            text-align: right;

                            img {
                                width: 25vw;
                                height: 25vw;
                            }
                        }
                        .rf {
                            float: right;
                            width: 60%;
                            //line-height: 3.125vw;
                            //padding: 8vw 0;
                            margin-top: -2vw;

                            p {
                                .fontFn(11);
                            }
                        }
                    }
                }
            }
        }  
    }
</style>